import {BrowserRouter,Routes,Route} from 'react-router-dom';
import { Helmet } from "react-helmet";
import './App.css';
import Home from './Components/Home';
import PgFOf from './Components/PgFOf';

function App() {
  return (

    <div className='app'>


      <BrowserRouter>
      <Helmet>
        <title>Vip Garden Resort- Luxury Jungle Getaway</title>


        <meta name="title" content="VIP Garden Resort - Luxury Jungle Getaway"/>
        <meta name="description" content="Discover the ultimate getaway at VIP Garden Resort, a hidden paradise located in the heart of a lush jungle. Book your stay today and experience luxury living in the midst of nature."/>
        <meta name="keywords" content="VIP Garden Resort, Luxury Jungle Resort, Hidden Paradise, Varkala beach, Jungle Getaway, Tranquil Escapes, Nature and Luxury, Luxurious Rooms, Refreshing Pool, Guided Jungle Tours, Lush Garden, Exotic Wildlife,Nearest"/>
        <meta name="robots" content="index, follow"/>
        <meta name="revisit-after" content="7 days"/>
        <meta name="author" content="VIP Garden Resort"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="theme-color" content="#161616"/>
        <meta name="author" content="Nandhu Esbee"></meta>
        <meta property="og:title" content="VIP Garden Resort - Luxury Jungle Getaway"/>
        <meta property="og:description" content="Discover the ultimate getaway at VIP Garden Resort, a hidden paradise located in the heart of a lush jungle. Book your stay today and experience luxury living in the midst of nature."/>
        <meta property="og:image" content="https://vipgardenresort.com/static/media/a2.fd6d7dc6121bc0c9cdea.jpg"/>
        <meta property="og:video" content="https://youtu.be/usTUToCzyrg"/>
        <meta property="og:url" content="https://vipgardenresort.com"/>
        <meta property="og:type" content="website"/>

        {/* <meta name="google-site-verification" content="YOUR_GOOGLE_SEARCH_CONSOLE_VERIFICATION_CODE"/>
        <meta name="msvalidate.01" content="YOUR_BING_WEBMASTER_TOOLS_VERIFICATION_CODE"/>
        <meta name="p:domain_verify" content="YOUR_PINTEREST_VERIFICATION_CODE"/> */}
        
      </Helmet>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="*" element={<PgFOf/>}/>
        </Routes>
      </BrowserRouter>
    </div>



  );
} 

export default App;
    