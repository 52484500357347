import React, { useState } from 'react'
import './Home.css'
import logo from './assets/images/logo1.jpg'
import a1 from './assets/images/a1.jpg'
import a2 from './assets/images/a2.jpg'
import a3 from './assets/images/a3.jpg'
import insta from './assets/icons/instagram.png'
import fb from './assets/icons/facebook.png'
import youtube from './assets/icons/youtube.png'
import location from './assets/icons/location.png'







const Home = () => {


    const [openMenu, setOpenMenu] = useState(false);
    const[openBooking,setOpenBooking]=useState(false);

    const menuToggle = () => {
        setOpenMenu(!openMenu);
        setOpenBooking(false)
    };

    const bookingToggle=()=>{
        setOpenBooking(!openBooking)
        setOpenMenu(false)
    }

  return (
    <div className="home-main-container">
        <div className='logo-container'>
            <div className='logo-sub-container'>
                <img src={logo} alt="logo"/>
                <div className='social'>
                    <div className='icons'><a href='https://www.instagram.com/vipgardenresort' target='_blank' rel="noreferrer"><img src={insta} alt="social"/></a></div>
                    <div className='icons'><a href='https://www.facebook.com/profile.php?id=100090110685625' target='_blank' rel="noreferrer"><img src={fb} alt="social"/></a></div>
                    <div className='icons'><a href='https://www.youtube.com/@vipgardenresort' target='_blank' rel="noreferrer"><img src={youtube} alt="social"/></a></div>
                    <div className='icons'><a href='https://www.google.com/maps/dir//vip+garden+resort/@8.739296,76.7006776,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3b05effed7a8c285:0x4e16522affac1640!2m2!1d76.7064328!2d8.7346818' target='_blank' rel="noreferrer"><img src={location} alt="social"/></a></div>
                </div>
                
            </div>


        </div>
        <div className='title-container'>
            <div className='title' >
                 <h1 aria-level="1">VIP GARDEN RESORT</h1>
                
        
                 
            </div>
            <h4 >WELCOME</h4>

            <div className='body-container'>




                
                <span>Welcome to VIP Garden Resort, a secluded haven located in the tranquil town of Varkala. Our holiday cottage is nestled amidst lush greenery, just a stone's throw away from the picturesque beach.</span>
                <br/><span>We look forward to welcoming you to VIP Garden Resort and ensuring that your stay with us is a memorable one. Book your stay today and experience the ultimate in relaxation and tranquility.</span>
            </div>
            <div class="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/hvX6IQOF-fQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> 
            </div>

            <div className='aboutus-container'>
                <span>ABOUT US</span>
                <div className='aboutus-cards'>
                    <div className='cards'>
                        <img src={a1} alt="about us"/>
                        <span>Our cottage is tastefully decorated with all the modern amenities to ensure a comfortable stay. We pride ourselves in providing a comfortable, clean and homely atmosphere that will make you feel right at home.
                        </span>
                    </div>
                    <div className='cards'>
                        <img src={a2} alt="about us"/>
                        <span>The resort's lush garden is the perfect place to unwind and rejuvenate, surrounded by the beauty of nature. Whether you're looking for a romantic getaway or a family holiday, VIP Garden Resort is the perfect destination for an unforgettable experience. 
                        </span>
                    </div>
                    <div className='cards'>
                        <img src={a3} alt="about us"/>
                        <span>Explore the local area and all it has to offer, from the stunning beaches to the rich cultural heritage. Take a dip in the crystal clear waters of the Arabian Sea or indulge in some local seafood delicacies.
                        </span>
                    </div>
                </div>



            </div>
            <div className='footer'>



                <span>COPYRIGHT © 2023 VIP GARDEN RESORT - ALL RIGHTS RESERVED.</span><br/><br/><hr /><br/><span>CREATED BY <a target='_blank' rel="noreferrer" href='https://www.instagram.com/nandhuesbeeoffl/'>NANDHU ESBEE</a></span>
            </div>
            
            
            

        </div>
        {openMenu&&       
         <div className='dialog'>
            CONTACT US
            <ul>
                
                <li>Mail us:<a href="mailto:vipgardenresort@gmail.com?subject=Customer%20-%20Feedback" target='_blank' rel="noreferrer"><br/>vipgardenresort@gmail.com</a></li>
                <br/>
                <li>Whatsapp:<a href="https://wa.me/+918891504345" target='_blank' rel="noreferrer"><br/>+91 8891504345</a></li>
            </ul>
        </div>
        }
        {openBooking&&
        <div className='book-now-dialogue'>
            BOOK WITH
            <ul>
                <li><a href='https://www.airbnb.co.in/rooms/801218557667866519?adults=1&children=0&infants=0&pets=0&check_in=2023-02-01&check_out=2023-02-06&source_impression_id=p3_1675117219_SX0Wjk23DBhaGXXx' target='_blank' rel="noreferrer">AirBNB</a></li>
                <li><a href='https://www.booking.com/hotel/in/vip-garden-resort.html' target='_blank' rel="noreferrer">Booking.com</a></li>

                <li><a href='https://india-stay.com/en/o/vip-garden-resort,5lmpd.html' target='_blank' rel="noreferrer">India Stay</a></li>

                <li><a href='https://planetofhotels.com/en/india/varkala/vip-garden-resort' target='_blank' rel="noreferrer">Planet of hotels</a></li>

            </ul>
            </div>}
        <button className='book_now' onClick={bookingToggle}>Book now</button>

        <button onClick={menuToggle}>        
            <svg viewBox="0 0 24 24" fill="currentColor" width="44" height="44" data-ux="Icon" class="chatbox"><g fill="#000000"><rect x="4" y="6" width="16" height="10.222" rx="1.129"></rect><path d="M8.977 18.578l.2-2.722a.564.564 0 01.564-.523h3.61c.548 0 .774.705.327 1.024l-3.81 2.721a.564.564 0 01-.89-.5z"></path></g></svg>
        </button>

    </div>
  )
}

export default Home